<template>
	<div>
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
			append-to-body
		>
			<div class="cardriverSelectbatch">
				<jy-query ref="form" :model="form">
					<jy-query-item prop="cName" label="司机姓名:" :span="1.5">
						<el-input placeholder="请输入" v-model="form.cName"></el-input>
					</jy-query-item>
					<jy-query-item prop="wNum" label="员工工号:" :span="1.5">
						<el-input placeholder="请输入" v-model="form.wNum"></el-input>
					</jy-query-item>
					<template slot="search">
						<el-button type="primary" @click="oncheck">查询</el-button>
						<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
					</template>
				</jy-query>
				<div class="choose_box">
					<div class="left_box">已选司机:</div>
					<div class="chooseDriver_box">
						<el-tag
							style="margin-right: 10px"
							v-for="tag in batchChooseList"
							:key="tag.driverId"
							closable
							@close="closeChoose(tag.driverId)"
						>
							{{ tag.cName }}
						</el-tag>
					</div>
				</div>
				<jy-table
					ref="dtable"
					:data="dataList"
					@select-all="selectchange"
					@select="selectchange"
					v-loading="loading"
					:row-style="setRowStyle"
				>
					<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
					<jy-table-column type="selection" width="40"></jy-table-column>
					<jy-table-column prop="cName" label="司机姓名"></jy-table-column>
					<jy-table-column prop="wNum" label="员工工号"></jy-table-column>
					<jy-table-column prop="carPlateNums" label="已关联车辆" v-if="!routeId"></jy-table-column>
					<jy-table-column label="性别" min-width="100">
						<template slot-scope="scope">{{ scope.row.gender | genderType }}</template>
					</jy-table-column>
					<jy-table-column prop="phone" label="联系电话"></jy-table-column>
					<jy-table-column label="供职状态" min-width="100">
						<template slot-scope="scope">{{ scope.row.sState | serType }}</template>
					</jy-table-column>
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				></jy-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<div>
					<el-button @click="cancel">取 消</el-button>
					<el-button type="primary" @click="selectSure">确 定</el-button>
				</div>
			</div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			title: "司机选择",
			routeId: "",
			form: {
				cName: "",
				wNum: ""
			},
			dialogVisible: false,
			formLabelWidth: "100px",
			batchChooseList: [],
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			loading: true
		};
	},
	activated() {},
	created() {
		// this.getList()
	},
	filters: {
		genderType(val) {
			if (val == "0") {
				return "女";
			} else if (val == "1") {
				return "男";
			}
		},
		serType(val) {
			if (val == "0") {
				return "离职";
			} else if (val == "1") {
				return "在职";
			}
		}
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				// this.resetForm('form')
				this.batchChooseList = [];
			}
		}
	},
	methods: {
		init(list, routeId) {
			this.batchChooseList = list;
			this.routeId = routeId || "";
			this.dialogVisible = true;
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		// 设置行内元素样式
		setRowStyle({ row }) {
			if (row.vehicleStrs) {
				return { "background-color": "#E6E6FA", opacity: "0.8" };
			}
		},
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
				serviceState: 1
			};
			let url = "/base/driver/queryPage";
			if (this.routeId) {
				url = "/base/vehicle/driver/queryDriverByRouteId";
				option = {
					...this.form,
					routeId: this.routeId,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				};
			}
			this.$http.post(url, option).then(
				({ detail }) => {
					let list = detail.list.map(item => {
						item.carPlateNums = item.vehicleInfos ? item.vehicleInfos.map(item => item.plateNum).join(",") : "";
						return {
							...item,
							...item.sysOrg,
							...item.driverInfo
						};
					});
					this.dataList = list;
					this.total = detail.total;
					this.loading = false;
					this.$nextTick(() => {
						let chooseList = this.dataList.filter(one => this.batchChooseList.some(item => item.driverId == one.driverId));
						chooseList.forEach(item => {
							this.$refs.dtable.toggleRowSelection(item, true);
						});
					});
				},
				() => {
					this.loading = false;
				}
			);
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			console.log(selection);
			let newList = [];
			this.batchChooseList.forEach(item => {
				if (!this.dataList.some(one => one.driverId == item.driverId) || selection.some(one => one.driverId == item.driverId)) {
					newList.push(item);
				}
			});
			selection.forEach(item => {
				if (!newList.some(one => one.driverId == item.driverId)) {
					newList.push(item);
				}
			});
			this.batchChooseList = newList;
		},
		closeChoose(id) {
			console.log(id);
			this.batchChooseList = this.batchChooseList.filter(item => item.driverId != id);
			let list = this.dataList.filter(one => one.driverId == id);
			if (list.length) {
				this.$refs.dtable.toggleRowSelection(list[0], false);
			}
		},
		// 确定选择
		selectSure() {
			if (this.batchChooseList.length === 0) {
				this.$message({
					message: "请选择司机",
					type: "warning"
				});
			} else {
				this.dialogVisible = false;
				this.$emit("choose", this.batchChooseList);
			}
		}
	}
};
</script>
<style lang="scss" scoped="cardriverSelectbatch">
.cardriverSelectbatch {
	.choose_box {
		.left_box {
			width: 90px;
			text-align: right;
			float: left;
			font-size: 14px;
			padding-right: 10px;
		}
		.chooseDriver_box {
			margin-left: 90px;
			height: 100px;
			border-radius: 4px;
			border: 1px solid #dcdfe6;
			padding: 10px;
		}
	}
}
</style>
